<template>
    <div>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0,minimum-scale=1.0">
        <div class="title">{{info.project_title}}</div>
        <div class="date">{{info.add_time}}</div>
        <center v-html="info.project_desc" style="padding:20px"></center>
    </div>
</template>
<script>
import { projectInfo} from "@/api/api";  
export default {
    data(){
        return{
            project_id:'',
            info:{}
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    created(){
        let project_id  = this.$route.params.id
        this.project_id = project_id
        this.getList()
    },
    methods:{
        async getList(){
           const {data:res} = await projectInfo(this.project_id)
           this.info = res      
        }
    }
}
</script>
<style scoped>
.title{
    font-size: 24px;
    font-weight: 600;
    padding-left: 20px;
    margin-top: 10px;
}
.date{
    font-size: 15px;
    font-weight: 600;
    padding-left: 20px;
    color: #818CA0;
    margin-top: 10px;
}
/deep/ img{
    width: 100% !important;
    height: 100%;
}
</style>